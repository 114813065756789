:root {
  --width: 100%;
  --height: 100%;
  --border-width: 200px;
  --border-height: 200px;

  --shrink-ration: 0.6;
  --hexagon-top-percent: 26%;
  --hexagon-left-percent: 31%;
}

@keyframes float-shadow {
  0% {
    // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    // transform: translate(-50%, 0);
    opacity: 1;
  }
  50% {
    // box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    // transform: translate(-50%, -20px);
    // opacity: 0.2;
    opacity: 1;
  }
  100% {
    // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    // transform: translate(-50%, 0);
    opacity: 1;
  }
}

@keyframes float {
  0% {
    // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translateY(0);
  }
  50% {
    // box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translateY(-20px);
  }
  100% {
    // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translateY(0);
  }
}

.gallery-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1080px;
  max-height: 80%;
  aspect-ratio: 1/1;

  &:after {
    content: "Crepi";
    position: absolute;
    top: -1rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2rem;
    z-index: -1;
  }

  .gallery {
    width: 100%;
    height: 100%;
    animation: float 6s ease-in-out infinite;

    &:after {
      content: "";
    }
    // &:hover {
    //   animation-play-state: paused;
    // }
    &:not(.floating) {
      animation-play-state: paused;
    }
  }
  .shadow {
    z-index: -1;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 20px;
    border-radius: 50%;
    background-color: transparent;
    animation: float-shadow 6s ease-in-out infinite;
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .flip-card {
    // perspective: 1000px; /* Remove this if you don't want the 3D effect */
    background-color: transparent;

    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
    }

    /* Do an horizontal flip when you move the mouse over the flip box container */
    // &:hover .flip-card-inner {
    //   transform: rotateY(180deg);
    // }
    &.clicked .flip-card-front {
      z-index: 1;
    }
    &.clicked .flip-card-inner {
      z-index: 2;
    }
    &.clicked .flip-card-inner {
      transform: rotateY(180deg);
    }

    /* Position the front and back side */
    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden; /* Safari */
      backface-visibility: hidden;
    }

    /* Style the front side (fallback if image is missing) */
    .flip-card-front {
      // background-color: #bbb;
      color: black;

      i {
        position: absolute;
        z-index: 2;
        top: 5%;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(200, 200, 200, 1);
        border-radius: 50%;
        padding: 0.5rem;
        opacity: 0.8;
        &.fa-twitch {
          color: white;
          background-color: #6441a5;
        }
        &.fa-discord {
          color: white;
          background-color: #7289da;
        }
        &.fa-tiktok {
          color: white;
          background-color: #333;
        }
        &.fa-twitter {
          color: white;
          background-color: #1da1f2;
        }
        &.fa-instagram {
          color: white;
          background-color: #e1306c;
        }
        &.fa-youtube {
          color: white;
          background-color: #ff0000;
        }
        &.fa-amazon {
          color: white;
          background-color: #ff9900;
        }
      }
    }

    /* Style the back side */
    .flip-card-back {
      background-color: dodgerblue;
      color: white;
      transform: rotateY(180deg);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      h1 {
        margin-bottom: 0.5rem;
        font-size: 2rem;
        margin: 0;
      }
      p.description {
        font-size: 1.2rem;
        padding: 0 2rem;
        margin: 0;
      }
      p.media-link {
        margin: 0;
      }

      a {
        margin: 0;
        position: relative;
        padding: 0.4rem 0.8rem;
        text-decoration: none;
        color: white;
        font-size: 1.8rem;
        transition: all 0.5s;
        z-index: 10;
        i {
          margin-right: 0.5rem;
        }
        &:hover {
          background-color: #555;
        }
      }

      &.twitch {
        background-color: #6441a5;
      }
      &.discord {
        background-color: #7289da;
      }
      &.tiktok {
        background-color: #333;
      }
      &.twitter {
        background-color: #1da1f2;
      }
      &.instagram {
        background-color: #e1306c;
      }
      &.youtube {
        background-color: #ff0000;
      }
      &.amazon {
        background-color: #ff9900;
      }
    }

    img {
      width: var(--width);
      height: var(--height);
    }

    &.clipped-border {
      -webkit-clip-path: polygon(
        50% 0%,
        95% 25%,
        95% 75%,
        50% 100%,
        5% 75%,
        5% 25%
      );
      clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
      // padding: 5px;
      // background: linear-gradient(grey, lightgrey);
      // background: rgba(255, 255, 255, 0.2);
      width: var(--border-width);
      // height: var(--border-height);
      // max-height: 250px;
      max-width: 33%;
      // height: 500px; // var(--height);
      // width: 500px; // var(--width);
      width: 100%;
      aspect-ratio: 1/1;
      transition: transform 0.2s;
      position: absolute;
      cursor: pointer;
      &:before {
        content: "";
        position: absolute;
        opacity: 0.5;
        width: 500px;
        height: 70px;
        background: white;
        top: 0;
        left: 0;
        z-index: 1;
        transform: rotate(45deg);
        transition: transform 0.5s;
      }
      &:hover {
        transform: scale(1.2);
        transition: transform 0.2s;
        z-index: 10;
        &:active {
          transform: scale(0.9);
        }
      }

      &:hover:before {
        transform: translate(-250px, 400%) rotate(45deg);
        transition: transform 0.5s;
      }

      &:nth-child(1) {
        top: calc(7%);
        left: calc(var(--hexagon-left-percent) / 2 + 2%);
      }

      &:nth-child(2) {
        top: calc(7%);
        left: calc(var(--hexagon-left-percent) * 3 / 2 + 2%);
      }

      &:nth-child(3) {
        top: calc(var(--hexagon-top-percent) + 7%);
        left: calc(0px + 2%);
      }

      &:nth-child(4) {
        top: calc(var(--hexagon-top-percent) + 7%);
        left: calc(var(--hexagon-left-percent) + 2%);
      }

      &:nth-child(5) {
        top: calc(var(--hexagon-top-percent) + 7%);
        left: calc(var(--hexagon-left-percent) * 2 + 2%);
      }

      &:nth-child(6) {
        top: calc(var(--hexagon-top-percent) * 2 + 7%);
        left: calc(var(--hexagon-left-percent) / 2 + 2%);
      }

      &:nth-child(7) {
        top: calc(var(--hexagon-top-percent) * 2 + 7%);
        left: calc(var(--hexagon-left-percent) * 3 / 2 + 2%);
      }
    }
  }
}

.clipped {
  -webkit-clip-path: polygon(
    50% 0%,
    95% 25%,
    95% 75%,
    50% 100%,
    5% 75%,
    5% 25%
  );
  clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
}

@media screen and (max-width: 500px) {
  .gallery-wrapper .flip-card {
    .flip-card-back {
      h1 {
        margin: 0;
        font-size: 0.6rem;
      }
      p.description {
        font-size: 0.6rem;
        padding: 0 0.5rem;
      }
      p.media-link {
      }

      a {
        padding: 0.4rem 0.8rem;
        font-size: 0.6rem;
        i {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .clipped-border:nth-child(1) {
    top: 0px;
    left: calc(118px * var(--shrink-ration));
  }

  .clipped-border:nth-child(2) {
    top: 0px;
    left: calc(353px * var(--shrink-ration));
  }

  .clipped-border:nth-child(3) {
    top: calc(98px * 2 * var(--shrink-ration));
    left: calc(0px * var(--shrink-ration));
  }

  .clipped-border:nth-child(4) {
    top: calc(98px * 2 * var(--shrink-ration));
    left: calc(235px * var(--shrink-ration));
  }

  .clipped-border:nth-child(5) {
    top: calc(98px * 2 * var(--shrink-ration));
    left: calc(470px * var(--shrink-ration));
  }

  .clipped-border:nth-child(6) {
    top: calc(98px * 4 * var(--shrink-ration));
    left: calc(118px * var(--shrink-ration));
  }

  .clipped-border:nth-child(7) {
    top: calc(98px * 4 * var(--shrink-ration));
    left: calc(353px * var(--shrink-ration));
  }
}

@media screen and (min-width: 501px) and (max-width: 700px) {
  .gallery-wrapper .flip-card {
    .flip-card-back {
      h1 {
        margin: 0;
        font-size: 0.8rem;
      }
      p.description {
        font-size: 0.8rem;
        padding: 0 1rem;
      }
      p.media-link {
      }

      a {
        padding: 0.4rem 0.8rem;
        font-size: 0.8rem;
        i {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

@media screen and (min-width: 701px) and (max-width: 900px) {
  .gallery-wrapper .flip-card {
    .flip-card-back {
      h1 {
        margin: 0;
        font-size: 1rem;
      }
      p.description {
        font-size: 1rem;
        padding: 0 1rem;
      }
      p.media-link {
      }

      a {
        padding: 0.4rem 0.8rem;
        font-size: 1.2rem;
        i {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
