// :root {
//   --width: 100%;
//   --height: 100%;
//   --border-width: 200px;
//   --border-height: 200px;
// }

html,
body,
#root {
  background: radial-gradient(mistyrose, pink);
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  font-family: "Raleway", sans-serif;
  margin: 0;
}

.app {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;

  > h1.title {
    position: absolute;
    margin: 0;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-family: "Fredoka", sans-serif;
    // font-family: "Bookochi", serif;
    font-size: 10rem;
    opacity: 0.1;
  }
}

.v-btn {
  color: #fff;
  position: fixed;
  top: 0;
  text-decoration: none;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }

  &.prev-v-btn {
    background: linear-gradient(135deg, #333 50%, rgba(0, 0, 0, 0) 50%);
    padding: 0.5rem 3rem 3rem 0.5rem;
    left: 0;
  }

  &.next-v-btn {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0) 50%, #333 50%);
    padding: 0.5rem 0.5rem 3rem 3rem;
    right: 0;
  }
}
